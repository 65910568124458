import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import { fetchQuery, useRelayEnvironment, useMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import "./SignUp.css";
import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
  LinearProgress as MuiLinearProgress,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import momentTimezone from "moment-timezone";
import { authConstants } from "../../utils/authConstants";
import { authUserType } from "../../utils/defaultStatus";
import { resetUser } from "../../redux/slices/user";
import useAuth from "../../hooks/useAuth";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const LinearProgress = styled(MuiLinearProgress)(spacing);

function MobileOtp({ setComponent, userTypeProp }) {
  const { serviceProviderSignUp } = useAuth();
  const environment = useRelayEnvironment();
  const [minutes, setMinutes] = useState(2);
  const [seconds, setSeconds] = useState(0);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector((state) => state.user.user);
  const [otpErrors, setOtpErrors] = useState("");
  const [newOtpStatus, setNewOtpStatus] = useState("");
  const dispatch = useDispatch();
  const currentTimeZone = momentTimezone();
  const currentTime = currentTimeZone
    .tz("Asia/Colombo")
    .format("YYYY-MM-DD HH:mm:ss");

  const [commit, isInFlight] = useMutation(graphql`
    mutation MobileOtpMutation(
      $user_name: String
      $otp_type: String
      $user_type: String
    ) {
      resendOtp(
        user_name: $user_name
        otp_type: $otp_type
        user_type: $user_type
      ) {
        otp_value
        otp_type
        user {
          user_name
        }
      }
    }
  `);

  const resendMobileOtp = (resetForm) => {
    switch (userTypeProp) {
      case authUserType.SHIPPER:
        commit({
          variables: {
            user_name: user.shipper_email,
            otp_type: "mobile",
            user_type: authUserType.SHIPPER,
          },
          onCompleted(data, error) {
            if (data.resendOtp) {
              setMinutes(2);
              setSeconds(0);
              resetForm();
              setOtpErrors("");
              setNewOtpStatus("success");
            }
            if (error) {
              setNewOtpStatus("fail");
            }
          },
        });
        break;
      case authUserType.SERVICE_PROVIDER:
        commit({
          variables: {
            user_name: user.service_provider.service_provider_email,
            otp_type: "mobile",
          },
          onCompleted(data, error) {
            if (data.resendOtp) {
              setMinutes(2);
              setSeconds(0);
              resetForm();
              setOtpErrors("");
              setNewOtpStatus("success");
            }
            if (error) {
              setNewOtpStatus("fail");
            }
          },
        });
        break;
      case authUserType.EMPLOYEE:
        commit({
          variables: {
            user_name: user.emp_company_email,
            otp_type: "mobile",
            user_type: authUserType.SERVICE_PROVIDER,
          },
          onCompleted(data, error) {
            if (data.resendOtp) {
              setMinutes(2);
              setSeconds(0);
              resetForm();
              setOtpErrors("");
              setNewOtpStatus("success");
            }
            if (error) {
              setNewOtpStatus("fail");
            }
          },
        });
        break;
      default:
        break;
    }
  };

  const fetchOtp = (otp, username, userType, mobileNumber1) => {
    fetchQuery(
      environment,
      graphql`
        query MobileOtpQuery(
          $user_name: String
          $otp_value: Int
          $otp_type: String
          $user_type: String
          $mobile_number: String
          $created_date: String
        ) {
          verifyUserAccount(
            user_name: $user_name
            otp_value: $otp_value
            otp_type: $otp_type
            user_type: $user_type
            mobile_number: $mobile_number
            created_date: $created_date
          ) {
            otp_value
            otp_type
          }
        }
      `,
      {
        mobile_number: mobileNumber1,
        user_type: userType,
        user_name: username,
        otp_value: otp,
        otp_type: "mobile",
        created_date: currentTime,
      }
    ).subscribe({
      start: () => {
        setIsLoading(true);
      },
      complete: () => {
        setIsLoading(false);
      },
      error: (e) => {
        setOtpErrors(e.message);
        setIsLoading(false);
      },
      next: (data) => {
        if (data.verifyUserAccount) {
          switch (userTypeProp) {
            case authUserType.EMPLOYEE:
              serviceProviderSignUp(user);
              dispatch(resetUser());
              navigate(
                `/${user.service_provider.service_provider_company_name}/dashboard`
              );
              window.location.reload();
              break;
            case authUserType.SHIPPER:
              setComponent(authConstants.BILLING);

              break;

            default:
              break;
          }
        }
      },
    });
  };

  const otpClick = (mobileOtp) => {
    const otpInt = parseInt(mobileOtp);
    switch (userTypeProp) {
      case authUserType.SHIPPER:
        fetchOtp(
          otpInt,
          user.shipper_email,
          authUserType.SHIPPER,
          user.shipper_mobile_number
        );
        break;
      case authUserType.EMPLOYEE:
        fetchOtp(
          otpInt,
          user.emp_company_email,
          authUserType.SERVICE_PROVIDER,
          user.service_provider_mobile_number
        );
        break;
      default:
    }
  };

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <>
      <Typography component="h1" variant="h2" align="center" gutterBottom>
        Verify your mobile phone number
      </Typography>
      <Typography component="h2" variant="body1" align="center">
        We have sent a SMS to your phone number with an OTP. Please enter the
        OTP provided in the SMS below to continue.
      </Typography>
      <Formik
        initialValues={{
          mobileOtp: "",
          submit: false,
        }}
        validationSchema={Yup.object().shape({
          mobileOtp: Yup.string().max(255).required("Mobile OTP is required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            otpClick(values.mobileOtp);
          } catch (error) {
            const message = error.message || "Something went wrong";

            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          resetForm,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert mt={2} mb={1} severity="warning">
                {errors.submit}
              </Alert>
            )}
            {otpErrors && (
              <Alert
                mb={4}
                variant="filled"
                severity="error"
                onClose={() => setOtpErrors("")}
              >
                {otpErrors}
              </Alert>
            )}
            {newOtpStatus === "fail" && (
              <Alert
                mb={4}
                variant="filled"
                severity="error"
                onClose={() => setNewOtpStatus("")}
              >
                New OTP sending failed. Please try again.
              </Alert>
            )}
            {newOtpStatus === "success" && (
              <Alert
                mb={4}
                variant="filled"
                severity="success"
                onClose={() => setNewOtpStatus("")}
              >
                New OTP sent successfully.
              </Alert>
            )}
            <TextField
              type="text"
              name="mobileOtp"
              label="Enter OTP"
              value={values.mobileOtp}
              error={Boolean(touched.mobileOtp && errors.mobileOtp)}
              fullWidth
              helperText={touched.mobileOtp && errors.mobileOtp}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                mr={2}
                style={{ marginBottom: "10px", padding: "0" }}
                disabled={!(minutes === 0 && seconds === 0)}
                onClick={() => resendMobileOtp(resetForm)}
              >
                Re-send OTP
              </Button>
              {minutes === 0 && seconds === 0 ? (
                <Typography
                  variant="h6"
                  align="end"
                  style={{
                    display: "inline-block",
                    marginBottom: "10px",
                    fontSize: "14px",
                  }}
                >
                  0:00 min
                </Typography>
              ) : (
                <Typography
                  variant="h6"
                  align="end"
                  style={{
                    display: "inline-block",
                    marginBottom: "10px",
                    fontSize: "14px",
                  }}
                >
                  {minutes}:{seconds < 10 ? `0${seconds}` : seconds} min
                </Typography>
              )}
            </div>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting || isLoading}
            >
              Next
            </Button>
            <div className="progress-content">
              <LinearProgress
                variant="determinate"
                value="50"
                className="progress"
              />
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}

export default MobileOtp;
