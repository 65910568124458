const addShipmentStatus = {
  SHIPMENT_FORM: "shipment-form",
  CONFIRM_SHIPMENTS: "confirm-shipments",
  REQUEST_PICKUP_SHIPMENTS: "request-pickup-shipments",
};

const paymentView = {
  PENDING: "pending",
  COMPLETED: "completed",
  VIEW_PAYMENT: "view-payment",
};

const userType = {
  SHIPPER: "shipper",
  SERVICE_PROVIDER: "service_provider",
};

const dispatchShipment = {
  SCANNED: "scanned",
  CONFIRMED: "confirmed",
};

const authUserType = {
  SHIPPER: "shipper",
  SERVICE_PROVIDER: "service_provider",
  EMPLOYEE: "employee",
  SYSTEM_ADMIN: "system_admin",
};

const outDeliveryShipment = {
  SCANNED: "scanned",
  CONFIRMED: "confirmed",
};

const payableViewSP = {
  SHIPPER_PAYABLE: "shipper-payable",
  PAYABLE_SUMMARY: "payable-summary",
  PAYMENT_NOTE: "payment-note",
};

const paymentViewSP = {
  PAYMENT_SUMMARIES: "payment-summaries",
  GENERATE_SUMMARY: "generate-summary",
  PAYMENT_SUMMARY: "payment-summary",
};

const returnDelivery = {
  SCANNED: "scanned",
  CONFIRMED: "confirmed",
};
const dispatchReturn = {
  SCANNED: "scanned",
  CONFIRMED: "confirmed",
};
const driverClearanceView = {
  DEFAULT: "default",
  CLEAR: "clear",
};
const pickupRequestType = {
  REQUEST: "request",
  AGING24: "aging24",
  AGING_MORE_THAN24: "aging_more_than24",
};
const shipmentHandlingType = {
  STANDARD: "standard",
  FRAGILE: "fragile",
};
const billingReceivableView = {
  INVOICE_SUMMARY: "invoice_summary",
  RECEIVABLE_SUMMARY: "receivable_summary",
  INVOICE_DETAILS: "invoice_details",
  DUE_INVOICES: "due_invoice",
  ALL_INVOICES: "all_invoice",
};

const hubCollectionView = {
  ALLHUBCOLLECTION: "allhubcollection",
  HUBCASHIN: "hubcashin",
  COLLECTIONOTE: "collection_note",
  PENDINGCOLLECTION: "pending_collection",
  ALLCOLLECTION: "all_collection",
};

const driverTypes = {
  TRANSIT: "transit",
};

const settingShipperView = {
  SHIPPER_SETTING: "shipper_setting",
  SHIPPER_DETAIL: "shipper_detail",
  EDIT_SHIPPER: "edit_shipper",
};

const addShipmentSP = {
  SHIPMENT_FORM: "shipment-form",
  CONFIRM_SHIPMENTS: "confirm-shipments",
  REQUEST_PICKUP_SHIPMENTS: "request-pickup-shipments",
};

const cycleFrequency = {
  DAILY: "daily",
  WEEKLY: "weekly",
  FORTNIGHTLY: "fortnightly",
  MONTHLY: "monthly",
};
const shipperPickups = {
  ALL_REQUEST: "all_request",
  REQUEST_DETAILS: "request_details",
};

const runSheets = {
  ALL_SHEETS: "all_sheets",
  SHEET_DETAILS: "sheet_details",
  ALL_RETURN_SHEETS: "all_return_sheets",
  RETURN_SHEET_DETAILS: "return_sheet_details",
};

const userActivationStatus = {
  UNPAID: "unpaid",
  PAID: "paid",
  COMPLETE: "complete",
  DEACTIVATE: "deactivate",
  ACTIVE: "active",
};

const paymentNoteDueTypes = {
  DUE_TODAY: "due_today",
  DUE_3_DAYS: "due_three_days",
  DUE_MORE_THAN_3_DAYS: "due_more_three_days",
};
const notificationType = {
  PICKUP_REQUEST: "pickup_request",
  SHIPPER_SIGNUP: "shipper_signup",
};
const landingPageImageType = {
  LOGO: "logo",
  BANNER: "banner",
};

module.exports = {
  addShipmentStatus,
  paymentView,
  userType,
  dispatchShipment,
  authUserType,
  outDeliveryShipment,
  payableViewSP,
  paymentViewSP,
  returnDelivery,
  dispatchReturn,
  driverClearanceView,
  pickupRequestType,
  shipmentHandlingType,
  billingReceivableView,
  hubCollectionView,
  driverTypes,
  settingShipperView,
  addShipmentSP,
  cycleFrequency,
  runSheets,
  shipperPickups,
  userActivationStatus,
  paymentNoteDueTypes,
  notificationType,
  landingPageImageType,
};
