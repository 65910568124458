import React, { useState, useCallback } from "react";
import styled from "styled-components/macro";
import Cropper from "react-easy-crop";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  Box,
  Slider,
  Typography,
  DialogContent,
  IconButton,
  DialogTitle,
  Stack,
  Alert as MuiAlert,
  Button as MuiButton,
} from "@mui/material";
import { spacing } from "@mui/system";
import axios from "axios";
import graphql from "babel-plugin-relay/macro";
import { useMutation } from "react-relay";
import getCroppedImg from "./CropImage";
import { authUserType, landingPageImageType } from "../../utils/defaultStatus";

const Button = styled(MuiButton)(spacing);
const Alert = styled(MuiAlert)(spacing);

const EditLandingPageImageDialog = ({
  ViewDialog,
  toggleViewImageDialog,
  image,
  fetchUserDetails,
  setIsErrorUploading,
  userType,
  imageType,
}) => {
  const { SHIPPER, SERVICE_PROVIDER } = authUserType;
  const { LOGO, BANNER } = landingPageImageType;
  const user = useSelector((state) => state.user.user);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [isUploading, setIsUploading] = useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [errorUpload, setError] = useState("");

  const [commitUpdateServiceProvider] = useMutation(graphql`
    mutation EditLandingPageImageDialogServiceProviderMutation(
      $id: ID
      $service_provider: ServiceProviderInputType!
    ) {
      updateServiceProvider(id: $id, service_provider: $service_provider) {
        _id
      }
    }
  `);

  const [commitUpdateShipper] = useMutation(graphql`
    mutation EditLandingPageImageDialogShipperMutation(
      $id: ID
      $shipper: ShipperUpdateInput!
    ) {
      updateShipperBySS(id: $id, shipper: $shipper) {
        _id
      }
    }
  `);

  // eslint-disable-next-line no-shadow
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const updateShipper = (bucketPath) => {
    let variables;
    if (imageType === LOGO) {
      variables = {
        id: user._id,
        shipper: {
          profile_setting: {
            logo: bucketPath,
          },
        },
      };
    } else if (imageType === BANNER) {
      variables = {
        id: user._id,
        shipper: {
          profile_setting: {
            banner: bucketPath,
          },
        },
      };
    }
    commitUpdateShipper({
      variables,
      onCompleted(data, error) {
        if (data.updateShipperBySS) {
          fetchUserDetails();
          toggleViewImageDialog(false);
        }
        if (error) {
          setIsErrorUploading(true);
        }
      },
    });
  };

  const updateServiceProvider = (bucketPath) => {
    const { service_provider } = user || {};
    const { _id: spId } = service_provider || {};
    let variables;
    if (imageType === LOGO) {
      variables = {
        id: spId || "",
        service_provider: {
          profile_setting: {
            logo: bucketPath,
          },
        },
      };
    } else if (imageType === BANNER) {
      variables = {
        id: spId || "",
        service_provider: {
          profile_setting: {
            banner: bucketPath,
          },
        },
      };
    }
    commitUpdateServiceProvider({
      variables,
      onCompleted(data, error) {
        if (data.updateServiceProvider) {
          fetchUserDetails();
          toggleViewImageDialog(false);
        }
        if (error) {
          setIsErrorUploading(true);
        }
      },
    });
  };

  const doneCroppedImage = useCallback(async () => {
    setIsUploading(true);
    try {
      const cropImage = await getCroppedImg(image, croppedAreaPixels, rotation);
      const appApiUrl = `${process.env.REACT_APP_API_URL}/upload/media/url`;

      let fileNameType = "";
      let userId = "";

      switch (userType) {
        case SHIPPER:
          userId = user._id;
          if (imageType === LOGO) {
            fileNameType = "LANDING_IMAGE_LOGO";
          } else if (imageType === BANNER) {
            fileNameType = "LANDING_IMAGE_BANNER";
          }
          break;
        case SERVICE_PROVIDER:
          userId = user.service_provider._id;
          if (imageType === LOGO) {
            fileNameType = "LANDING_IMAGE_LOGO";
          } else if (imageType === BANNER) {
            fileNameType = "LANDING_IMAGE_BANNER";
          }
          break;

        default:
          break;
      }

      const data = JSON.stringify({
        mediaType: "IMAGE",
        fileName: `${fileNameType}_${userId.toString()}`,
      });

      const config = {
        method: "put",
        url: appApiUrl,
        headers: {
          "Content-Type": "application/json",
        },
        data,
      };

      await axios(config)
        .then(async (response) => {
          if (response.status === 200) {
            const { uploadSignedUrl, bucketPath } = response?.data || {};
            const configBody = {
              method: "put",
              url: uploadSignedUrl,
              headers: {
                "Content-Type": "image/jpeg",
              },
              data: cropImage[1],
            };

            await axios(configBody)
              .then((uploadResponse) => {
                if (uploadResponse.status === 200) {
                  if (userType === SHIPPER) {
                    updateShipper(bucketPath);
                  } else if (userType === SERVICE_PROVIDER) {
                    updateServiceProvider(bucketPath);
                  }
                }
              })
              .catch(async (error) => {
                const { message } = error;
                setError(message);
                setIsUploading(false);
              });
          }
        })
        .catch(async (error) => {
          const { message } = error;
          setError(message);
          setIsUploading(false);
        });

      setIsUploading(false);
      setIsErrorUploading(false);
      fetchUserDetails();
    } catch (e) {
      Error(e);
      setIsUploading(false);
      setIsErrorUploading(true);
    }
  }, [croppedAreaPixels, rotation]);

  const onClose = useCallback(() => {
    toggleViewImageDialog(false);
  }, []);

  return (
    <div>
      <Dialog
        open={ViewDialog}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">Focus to crop</DialogTitle>
        {errorUpload && (
          <Alert
            mb={4}
            variant="filled"
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => setError("")}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {errorUpload}
          </Alert>
        )}

        <DialogContent>
          <div
            style={{
              position: "relative",
              width: "100%",
              height: 500,
              background: "#333",
            }}
          >
            <Cropper
              image={image}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              aspect={imageType === LOGO ? 1 / 1 : 4 / 1}
              onCropChange={setCrop}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <Box width={800}>
            <div style={{ display: "flex", flex: "1", alignItems: "center" }}>
              <Typography variant="overline" style={{ alignItems: "center" }}>
                Zoom
              </Typography>
              <Slider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                style={{ padding: "22px 0px", marginLeft: 53 }}
                // eslint-disable-next-line no-shadow
                onChange={(e, zoom) => setZoom(zoom)}
              />
            </div>
            <div style={{ display: "flex", flex: "1", alignItems: "center" }}>
              <Typography variant="overline" style={{ alignItems: "center" }}>
                Rotation
              </Typography>
              <Slider
                value={rotation}
                min={0}
                max={360}
                step={1}
                aria-labelledby="Rotation"
                style={{ padding: "22px 0px", marginLeft: 32 }}
                // eslint-disable-next-line no-shadow
                onChange={(e, rotation) => setRotation(rotation)}
              />
            </div>
          </Box>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginBottom: "10px",
              marginTop: "20px",
            }}
          >
            <Stack direction="row" spacing={2}>
              <Button
                color="success"
                onClick={doneCroppedImage}
                variant="outlined"
                style={{ flexShrink: 0, marginLeft: 16 }}
                disabled={isUploading}
              >
                {isUploading ? "Uploading..." : "Done"}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                mt={3}
                onClick={onClose}
                disabled={isUploading}
              >
                Close
              </Button>
            </Stack>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default EditLandingPageImageDialog;
