export const commonConstants = {
  SUCCESS: "success",
  FAILED: "failed",
};
export const loginType = {
  SHIPPER: "shipper",
  SERVICE_PROVIDER: "service_provider",
  EMPLOYEE: "employee",
};
export const paymentTabType = {
  PAYMENT_SUMMARY: "payment_summary",
  PENDING_PAYMENTS: "pending_payments",
  COMPLETED_PAYMENTS: "completed_payments",
  PAYMENT_REPORT: "payment_report",
};
export const invoiceTabType = {
  INVOICE_SUMMARY: "invoice_summary",
  PENDING_INVOICES: "pending_invoices",
  COMPLETED_INVOICES: "completed_invoices",
  INVOICE_REPORT: "invoice_report",
};
export const priceCardType = {
  COMPLETED: "completed",
  INCOMPLETE: "incompleted",
};
export const videoTutTabType = {
  ADD_TUTORIALS: "add_tutorials",
  ASSIGN_TUTORIALS: "assign_tutorials",
  UPDATE_TUTORIAL: "update_tutorial",
  ADD_CATEGORY: "add_category",
};
export const mediaAvailableKey = {
  NO_IMAGES: "no_images",
  AVAILABLE: "available",
};
export const landingImagesRequestTypes = {
  LANDING_IMAGE_LOGO_SHIPPER: "LANDING_IMAGE_LOGO_SHIPPER",
  LANDING_IMAGE_BANNER_SHIPPER: "LANDING_IMAGE_BANNER_SHIPPER",
  LANDING_IMAGE_LOGO_SP: "LANDING_IMAGE_LOGO_SP",
  LANDING_IMAGE_BANNER_SP: "LANDING_IMAGE_BANNER_SP",
};
export const collectionFilterSelectorTypes = {
  ALL_HUBS: "all_hubs",
};
export const userSelectionType = {
  ALL_SHIPPERS: "all_shippers",
};

export const componentTypes = {
  RETURN_REPORT: "return_report",
};
