import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tutorials: [],
};

export const AdminTutorialsSlice = createSlice({
  name: "adminTutorialsSlice",
  initialState,
  reducers: {
    setTutorials: (state, action) => {
      state.tutorials = action.payload;
    },

    updateTutorialList: (state, action) => {
      const updateTutorial = action.payload;
      const objIndex = state.tutorials.findIndex(
        (obj) => obj._id === updateTutorial._id
      );
      state.tutorials[objIndex] = updateTutorial;
    },
  },
});

export const { setTutorials, updateTutorialList } = AdminTutorialsSlice.actions;
export default AdminTutorialsSlice.reducer;
