import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import graphql from "babel-plugin-relay/macro";
import axios from "axios";
import { fetchQuery, useRelayEnvironment } from "react-relay";
import { useDispatch } from "react-redux";
import _ from "lodash";
import Introduction from "./RasutomairuLandingIntroduction";
import Loader from "../../../components/Loader";
import Page404 from "../../auth/Page404";
import { setServiceProvider } from "../../../redux/slices/user";
import { landingImagesRequestTypes } from "../../../utils/commonConstants";

function RasutomairuLandingPage({ onEdit, setIsErrorUploading }) {
  const { serviceProvider } = useParams();
  const [currentUser, setCurrentUser] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const environment = useRelayEnvironment();
  const location = useLocation();
  const dispatch = useDispatch();
  const [spLogoDownloadUrl, setSpLogoDownloadUrl] = useState("");
  const [spBannerDownloadUrl, setSpBannerDownloadUrl] = useState("");

  const fetchUserDetails = async () => {
    fetchQuery(
      environment,
      graphql`
        query RasutomairuLandingPagewQuery(
          $service_provider_company_name: String
        ) {
          getServiceProviderByCompanyName(
            service_provider_company_name: $service_provider_company_name
          ) {
            _id
            service_provider_id
            service_provider_full_name
            service_provider_company_name
            profile_setting {
              logo
              banner
            }
          }
        }
      `,
      {
        service_provider_company_name: serviceProvider,
      }
    ).subscribe({
      start: () => {
        setIsLoading(true);
      },
      complete: () => {
        setIsLoading(false);
      },
      error: () => {},
      next: (data) => {
        const { LANDING_IMAGE_LOGO_SP, LANDING_IMAGE_BANNER_SP } =
          landingImagesRequestTypes;

        const getDownloadUrls = async () => {
          const { _id } = data.getServiceProviderByCompanyName || {};
          const appApiLogoUrl = `${process.env.REACT_APP_API_URL}/download/media/${LANDING_IMAGE_LOGO_SP}/${_id}`;
          const appApiBannerUrl = `${process.env.REACT_APP_API_URL}/download/media/${LANDING_IMAGE_BANNER_SP}/${_id}`;

          try {
            const config = {
              method: "get",
              url: appApiLogoUrl,
              headers: {},
            };

            await axios(config)
              .then((response) => {
                const { status, request } = response;
                if (status === 200) {
                  setSpLogoDownloadUrl(request?.responseURL || "");
                  setIsLoading(false);
                }
              })
              .catch(() => {
                setIsLoading(false);
              });
          } catch (e) {
            setIsLoading(false);
          }

          try {
            const config = {
              method: "get",
              url: appApiBannerUrl,
              headers: {},
            };

            await axios(config)
              .then((response) => {
                const { status, request } = response;
                if (status === 200) {
                  setSpBannerDownloadUrl(request?.responseURL || "");
                  setIsLoading(false);
                }
              })
              .catch(() => {
                setIsLoading(false);
              });
          } catch (error) {
            setIsLoading(false);
          }
        };

        if (data.getServiceProviderByCompanyName) {
          getDownloadUrls();
          setCurrentUser(data.getServiceProviderByCompanyName);
          dispatch(setServiceProvider(data.getServiceProviderByCompanyName));
        }
      },
    });
  };

  useEffect(() => {
    fetchUserDetails();
  }, [location]);

  if (isLoading) return <Loader />;

  if (_.isEmpty(currentUser)) return <Page404 />;

  return (
    <Introduction
      spLogoDownloadUrl={spLogoDownloadUrl}
      spBannerDownloadUrl={spBannerDownloadUrl}
      currentUser={currentUser}
      onEdit={onEdit}
      fetchUserDetails={fetchUserDetails}
      setIsErrorUploading={setIsErrorUploading}
    />
  );
}

export default RasutomairuLandingPage;
