import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { Typography, Paper, Grid } from "@mui/material";
import UserPlaceOrderForm from "./UserPlaceOrderForm";

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function UserPlaceOrder() {
  return (
    <Wrapper>
      <Helmet title="Place order" />
      <Grid container spacing={2} justifyContent="center">
        <Grid
          container
          mt={2}
          spacing={2}
          item
          md={4}
          xs={12}
          justifyContent="center"
          alignItems="center"
        >
          <Typography component="h1" variant="h2" align="center" gutterBottom>
            Place your order
          </Typography>
        </Grid>
      </Grid>

      <UserPlaceOrderForm />
    </Wrapper>
  );
}

export default UserPlaceOrder;
