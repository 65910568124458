import * as React from "react";
import { useState, useEffect } from "react";
import styled, { withTheme } from "styled-components/macro";
import { darken } from "polished";
import {
  Search as SearchIcon,
  Settings,
  User,
  Plus,
  HelpCircle,
} from "react-feather";
import { useTranslation } from "react-i18next";
import "../../styles/common.css";
import {
  Grid,
  Hidden,
  InputBase,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  Tooltip,
  Button,
  Alert as MuiAlert,
  Stack,
  Snackbar,
} from "@mui/material";
import { spacing } from "@mui/system";
import { Menu as MenuIcon } from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import graphql from "babel-plugin-relay/macro";
import { fetchQuery, useRelayEnvironment } from "react-relay";
import { useNavigate } from "react-router-dom";
import NavbarUserDropdown from "./NavbarUserDropdown";
import { resetUser } from "../../redux/slices/user";
import ProgressViewModal from "../../pages/shipments/progress_view/modal/ProgressViewModal";
import { authUserType } from "../../utils/defaultStatus";
import { employeeTypeConstants } from "../../utils/employeeTypeConstants";
import Loader from "../MiniLoader";
import NavbarNotificationsDropdown from "./NavbarNotificationsDropdown";

const Alert = styled(MuiAlert)(spacing);

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
    width: 160px;
  }
`;

function Navbar({ onDrawerToggle }) {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  const environment = useRelayEnvironment();
  const [shipmentDetails, setShipmentDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [openProgressModal, setOpenProgressModal] = React.useState(false);
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.user.user);
  const [isShipmentNotFound, setIsShipmentNotFound] = useState(false);

  const handleChange = (e) => {
    setIsShipmentNotFound(false);
    setSearchText(e.target.value);
  };

  const fetchShipmentDetails = async () => {
    let shipperId = "";
    let serviceProviderId = "";
    const user = await localStorage.getItem("user");
    const userJ = JSON.parse(user);

    switch (userJ.user_type) {
      case authUserType.SHIPPER:
        shipperId = userJ._id;
        break;
      case authUserType.SERVICE_PROVIDER:
        serviceProviderId = userJ._id;
        break;
      default:
        break;
    }

    fetchQuery(
      environment,
      graphql`
        query NavbarQuery(
          $traking_id: String!
          $service_provider_id: ID
          $shipper_id: ID
        ) {
          getShipmentByTRK(
            traking_id: $traking_id
            service_provider_id: $service_provider_id
            shipper_id: $shipper_id
          ) {
            _id
            traking_id
            shipment_created_time
            shipper {
              _id
              shipper_id
              shipper_full_name
              shipper_company_name
              shipper_email
              shipper_address_line1
              shipper_phone1
              pickup_locations {
                hub {
                  _id
                  hub_name
                  hub_code
                }
                city {
                  _id
                  city_name
                }
              }
            }
            weight
            qty
            shipment_description
            declared_value
            prepaid
            cod_value
            handling_type
            recipient_name
            recipient_phone1
            recipient_phone2
            recipient_address_line1
            recipient_address_line2
            recipient_postal_code
            recipient_address_province {
              _id
              province_name
            }
            district {
              _id
              district_name
            }
            city {
              _id
              city_name
            }
            shipment_change_log {
              change_date
              change_field
              history_field
              event
              location
            }
            ownership {
              _id
              emp_full_name
              emp_mobile1
              emp_hub {
                hub_name
              }
            }
            service_provider {
              _id
              service_provider_full_name
              service_provider_company_name
            }
          }
        }
      `,
      {
        traking_id: searchText,
        service_provider_id: serviceProviderId,
        shipper_id: shipperId,
      }
    ).subscribe({
      start: () => {
        setIsLoading(true);
      },
      complete: () => {
        setIsLoading(false);
      },
      error: () => {},
      next: (data) => {
        if (data.getShipmentByTRK) {
          setShipmentDetails(data.getShipmentByTRK);
          setIsShipmentNotFound(false);
          setOpenProgressModal(true);
        }
        if (data.getShipmentByTRK === null) {
          setIsShipmentNotFound(true);
        }
      },
    });
  };

  const handleSearch = (e) => {
    if (e.key === "Enter") {
      fetchShipmentDetails();
    }
  };

  const getSPDisplayName = () => {
    const user = localStorage.getItem("user");
    const userJson = JSON.parse(user);
    if (userJson) {
      return {
        userDisplayName: userJson.shipper_company_name,
        userSPDisplayName:
          userJson.service_provider.service_provider_company_name,
      };
    }
    return "";
  };

  const getUserType = () => {
    const user = localStorage.getItem("user");
    const userJson = JSON.parse(user);
    if (userJson) {
      return userJson.user_type;
    }
    return "";
  };

  const getEmployeeType = () => {
    const user = localStorage.getItem("user");
    const userJson = JSON.parse(user);
    if (userJson) {
      return userJson.employee_type;
    }
    return "";
  };

  useEffect(() => {
    dispatch(resetUser());
  }, []);

  const handleClose = () => {
    setIsShipmentNotFound(false);
  };
  return (
    <>
      <AppBar position="sticky" elevation={0} className="responsiveContainer">
        <Toolbar>
          <Grid container alignItems="center">
            <Hidden mdUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                  size="large"
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <Input
                  placeholder={t("Search")}
                  onChange={handleChange}
                  onKeyDown={handleSearch}
                />
              </Search>
            </Grid>
            {isLoading && <Loader size={25} />}
            <Grid item xs />
            <Grid item>
              {/* <NavbarMessagesDropdown /> */}

              {/* <NavbarLanguagesDropdown /> */}
              {getUserType() === authUserType.SHIPPER && (
                <Tooltip title="Add Shipments">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ marginRight: "20px" }}
                    disabled={!currentUser.is_config}
                    onClick={() =>
                      navigate(
                        `/${getSPDisplayName().userSPDisplayName}/shipper/${
                          getSPDisplayName().userDisplayName
                        }/shipments/add_shipments`
                      )
                    }
                  >
                    <Plus /> Add Shipments
                  </Button>
                </Tooltip>
              )}
              {getUserType() === authUserType.EMPLOYEE && (
                <Tooltip title="Add Shipments">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ marginRight: "20px" }}
                    onClick={() =>
                      navigate(
                        `/${
                          getSPDisplayName().userSPDisplayName
                        }/shipments/add_shipments`
                      )
                    }
                  >
                    <Plus /> Add Shipments
                  </Button>
                </Tooltip>
              )}

              {getEmployeeType() === employeeTypeConstants.SITE_ADMIN &&
                currentUser.service_provider_setup_status === true && (
                  <Tooltip title="Settings">
                    <IconButton
                      color="inherit"
                      onClick={() =>
                        navigate(
                          `/${
                            getSPDisplayName().userSPDisplayName
                          }/settings/operations`
                        )
                      }
                      size="large"
                    >
                      <Settings />
                    </IconButton>
                  </Tooltip>
                )}
              {getUserType() === authUserType.SHIPPER && (
                <>
                  <Tooltip title="Help">
                    <IconButton
                      color="inherit"
                      onClick={() =>
                        navigate(
                          `/${getSPDisplayName().userSPDisplayName}/shipper/${
                            getSPDisplayName().userDisplayName
                          }/platform-guide`
                        )
                      }
                      size="large"
                    >
                      <HelpCircle />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Settings">
                    <IconButton
                      color="inherit"
                      onClick={() =>
                        navigate(
                          `/${getSPDisplayName().userSPDisplayName}/shipper/${
                            getSPDisplayName().userDisplayName
                          }/settings/landing_page_settings`
                        )
                      }
                      size="large"
                    >
                      <Settings />
                    </IconButton>
                  </Tooltip>
                </>
              )}
              {getUserType() === authUserType.EMPLOYEE && (
                <>
                  <NavbarNotificationsDropdown />
                  <Tooltip title="Help">
                    <IconButton
                      color="inherit"
                      onClick={() =>
                        navigate(
                          `/${
                            getSPDisplayName().userSPDisplayName
                          }/platform-guide`
                        )
                      }
                      size="large"
                    >
                      <HelpCircle />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Profile">
                    <IconButton
                      color="inherit"
                      onClick={() =>
                        navigate(
                          `/${
                            getSPDisplayName().userSPDisplayName
                          }/account_settings`
                        )
                      }
                      size="large"
                    >
                      <User />
                    </IconButton>
                  </Tooltip>
                </>
              )}

              <NavbarUserDropdown />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {currentUser.user_type === authUserType.EMPLOYEE &&
        currentUser.service_provider_setup_status === false && (
          <Alert
            severity="warning"
            action={
              <Button
                color="primary"
                mt={3}
                onClick={() =>
                  navigate(
                    `/${getSPDisplayName().userSPDisplayName}/setup_operations`
                  )
                }
              >
                Start setup guide
              </Button>
            }
          >
            Please complete operation setup.
          </Alert>
        )}
      {currentUser.user_type === authUserType.SHIPPER &&
        !currentUser.is_config && (
          <Alert severity="warning">
            You cannot submit shipments yet. Please contact service provider{" "}
            <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
              {currentUser?.service_provider?.service_provider_company_name}
            </span>{" "}
            to setup your price card, payment cycle and billing cycle.
          </Alert>
        )}
      <ProgressViewModal
        open={openProgressModal}
        setOpen={setOpenProgressModal}
        shipmentDetails={shipmentDetails}
      />
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={isShipmentNotFound}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity="warning"
            sx={{ width: "100%" }}
          >
            Entered shipment {searchText} is not available
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
}

export default withTheme(Navbar);
