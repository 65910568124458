import React, { useState } from "react";
import styled from "styled-components/macro";
import {
  Container,
  Button as MuiButton,
  Typography as MuiTypography,
  TextField,
  Alert as MuiAlert,
  Divider as MuiDivider,
} from "@mui/material";
import { spacing } from "@mui/system";
import { Search as SearchIcon } from "react-feather";
import graphql from "babel-plugin-relay/macro";
import { fetchQuery, useRelayEnvironment } from "react-relay";
import { useNavigate } from "react-router-dom";
import ProgressViewModal from "../../shipments/progress_view/modal/ProgressViewModal";
import { commonConstants } from "../../../utils/commonConstants";
import UploadBannerButton from "../../shipperSettings/landingPageSettings/BannerUploadButton";
import UploadLogoButton from "../../shipperSettings/landingPageSettings/LogoUploadButton";
import MiniLoader from "../../../components/MiniLoader";
import LandingLogo from "../../../utils/images/default-logo.png";
import LandingBanner from "../../../utils/images/logo_transparent_background.png";

const Typography = styled(MuiTypography)(spacing);
const Button = styled(MuiButton)(spacing);
const Divider = styled(MuiDivider)(spacing);

const Title = styled(Typography)`
  opacity: 0.9;
  line-height: 1.4;
  font-size: 1.75rem;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};

  ${(props) => props.theme.breakpoints.up("sm")} {
    font-size: 2rem;
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 2.5rem;
  }

  span {
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;

const CompanyName = styled(Typography)`
  opacity: 0.9;
  line-height: 1.4;
  font-size: 2rem;
  margin-left: 40px;
  margin-top: 10px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};

  @media (max-width: 760px) {
    font-size: 1.5rem;
  }

  @media (max-width: 650px) {
    font-size: 1.2rem;
    margin-top: -20px;
    margin-left: 0;
    text-align: center;
  }
`;

const SubTitle = styled(Typography)`
  opacity: 0.9;
  line-height: 0.5;
  font-size: 1rem;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};

  ${(props) => props.theme.breakpoints.up("sm")} {
    font-size: 1rem;
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 1.5rem;
  }

  span {
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;

const LoginButton = styled(Button)`
  margin-right: 10px;

  @media (max-width: 650px) {
    margin-bottom: 10px;
    min-width: 300px;
    margin-right: 0;
  }
`;

const BannerImage = styled.div`
  position: relative;
  width: 100%;
  min-height: 150px;
  background-color: #e0e0e0;

  @media (max-width: 760px) {
    min-height: 100px;
  }

  @media (max-width: 650px) {
    min-height: 50px;
  }
`;

const DetailsContainer = styled.div`
  display: flex;

  @media (max-width: 650px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const Logo = styled.div`
  height: 150px;
  width: 150px;
  background-color: white;
  position: relative;
  display: block;
  top: -20px;
  left: 30px;
  border: 5px solid white;

  @media (max-width: 650px) {
    height: 120px;
    width: 120px;
    left: 0;
  }
`;

const LogoContent = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid #e0e0e0;
`;

const LogoContainer = styled.div`
  display: flex;

  @media (max-width: 650px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const UserDetailsContainer = styled.div`
  width: 100%;
  position: relative;
`;

const LoginContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  position: absolute;
  bottom: 20px;
  right: 30px;

  @media (max-width: 650px) {
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    bottom: auto;
    right: auto;
  }
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

const EditLogoOverlay = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 5px;
`;

const EditBannerOverlay = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 5px;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

const Alert = styled(MuiAlert)(spacing);

function ShipperLanding({
  spLogoDownloadUrl,
  spBannerDownloadUrl,
  currentUser,
  onEdit,
  fetchUserDetails,
  setIsErrorUploading,
}) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [shipmentDetails, setShipmentDetails] = useState({});
  const [openProgressModal, setOpenProgressModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [shipmentFoundStatus, setShipmentFoundStatus] = useState("");
  const environment = useRelayEnvironment();
  const defaultBanner = LandingBanner;
  const defaultLogo = LandingLogo;

  const { _id: shipperId, shipper_company_name: shipperCompanyName } =
    currentUser;

  const handleChange = (e) => {
    setShipmentFoundStatus("");
    setSearchText(e.target.value);
  };

  const fetchShipmentDetails = async () => {
    fetchQuery(
      environment,
      graphql`
        query ShipperLandingQuery(
          $traking_id: String!
          $service_provider_id: ID
          $shipper_id: ID
        ) {
          getShipmentByTRK(
            traking_id: $traking_id
            service_provider_id: $service_provider_id
            shipper_id: $shipper_id
          ) {
            _id
            traking_id
            shipment_created_time
            shipper {
              _id
              shipper_id
              shipper_full_name
              shipper_company_name
              shipper_email
              shipper_address_line1
              shipper_phone1
              pickup_locations {
                hub {
                  _id
                  hub_name
                  hub_code
                }
                city {
                  _id
                  city_name
                }
              }
            }
            weight
            qty
            shipment_description
            declared_value
            prepaid
            cod_value
            handling_type
            recipient_name
            recipient_phone1
            recipient_phone2
            recipient_address_line1
            recipient_address_line2
            recipient_postal_code
            recipient_address_province {
              _id
              province_name
            }
            district {
              _id
              district_name
            }
            city {
              _id
              city_name
            }
            shipment_change_log {
              change_date
              change_field
              history_field
              event
              location
            }
            ownership {
              _id
              emp_full_name
              emp_mobile1
              emp_hub {
                hub_name
              }
            }
            service_provider {
              _id
              service_provider_full_name
              service_provider_company_name
            }
          }
        }
      `,
      {
        traking_id: searchText,
        service_provider_id: "",
        shipper_id: shipperId,
      }
    ).subscribe({
      start: () => {
        setIsLoading(true);
      },
      complete: () => {
        setIsLoading(false);
      },
      error: () => {},
      next: (data) => {
        if (data.getShipmentByTRK) {
          setShipmentDetails(data.getShipmentByTRK);
          setOpenProgressModal(true);
        }
        if (data.getShipmentByTRK === null) {
          setShipmentFoundStatus(commonConstants.FAILED);
        }
      },
    });
  };

  const handleSearch = (e) => {
    if (e.key === "Enter" && searchText) {
      fetchShipmentDetails();
    }
  };

  return (
    <Container
      sx={{ paddingLeft: 0, backgroundColor: "white", flex: "1 1 auto" }}
      disableGutters
    >
      <div>
        <BannerImage>
          <Image
            src={spBannerDownloadUrl || defaultBanner}
            alt="custom-banner"
          />
          {onEdit && (
            <EditBannerOverlay>
              <UploadBannerButton
                fetchUserDetails={fetchUserDetails}
                setIsErrorUploading={setIsErrorUploading}
              />
            </EditBannerOverlay>
          )}
        </BannerImage>
      </div>
      <DetailsContainer>
        <LogoContainer>
          <Logo>
            <LogoContent>
              <Image src={spLogoDownloadUrl || defaultLogo} alt="custom-logo" />
              {onEdit && (
                <EditLogoOverlay>
                  <UploadLogoButton
                    fetchUserDetails={fetchUserDetails}
                    setIsErrorUploading={setIsErrorUploading}
                  />
                </EditLogoOverlay>
              )}
            </LogoContent>
          </Logo>
        </LogoContainer>
        <UserDetailsContainer>
          <CompanyName gutterBottom>{shipperCompanyName}</CompanyName>
          <LoginContainer>
            <LoginButton
              color="primary"
              variant="contained"
              disabled={onEdit}
              onClick={() => navigate(`/${shipperCompanyName}/auth`)}
            >
              Login
            </LoginButton>
          </LoginContainer>
        </UserDetailsContainer>
      </DetailsContainer>
      <Divider />
      <BodyContainer>
        <Title variant="h1" gutterBottom>
          Track shipment status
        </Title>
        <div
          style={{
            position: "relative",
            display: "inline-block",
          }}
        >
          {isLoading ? (
            <div
              style={{
                position: "absolute",
                right: 10,
                top: 15,
                width: 20,
                height: 20,
              }}
            >
              <MiniLoader size={25} />
            </div>
          ) : (
            <SearchIcon
              style={{
                position: "absolute",
                right: 10,
                top: 15,
                width: 20,
                height: 20,
              }}
            />
          )}
          <TextField
            style={{ width: "300px" }}
            id="standard-search"
            label="Enter tracking ID"
            onChange={handleChange}
            onKeyDown={handleSearch}
            disabled={onEdit || isLoading}
          />
        </div>
        {shipmentFoundStatus === commonConstants.FAILED && (
          <Alert
            mb={4}
            severity="warning"
            style={{ marginTop: "10px", width: "330px" }}
            onClose={() => setShipmentFoundStatus("")}
          >
            No shipment found on {searchText}
          </Alert>
        )}
        <SubTitle sx={{ marginTop: "20px", marginBottom: "20px" }}>
          please enter one shipment tracking ID
        </SubTitle>
      </BodyContainer>
      <ProgressViewModal
        open={openProgressModal}
        setOpen={setOpenProgressModal}
        shipmentDetails={shipmentDetails}
      />
    </Container>
  );
}

export default ShipperLanding;
