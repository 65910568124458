import { Users, Home, Play } from "react-feather";

const pagesSection = [
  {
    href: `/admin/home`,
    icon: Home,
    title: "Home",
  },
  {
    href: `/admin/service_providers`,
    icon: Users,
    title: "Service Providers",
  },
  {
    href: `/admin/tutorials`,
    icon: Play,
    title: "Video Tutorials",
    children: [
      {
        href: "/admin/tutorials/upload-videos",
        title: "Upload Videos",
      },
      {
        href: "/admin/tutorials/assign-tutorials",
        title: "Assign Tutorials",
      },
    ],
  },
];

const navItems = [
  {
    title: "Admin",
    pages: pagesSection,
  },
];

export default navItems;
