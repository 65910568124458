import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // Zones
  priceCardZones: [],
  // Cities
  allCities: [],
  filteredCities: [],
  emptyCities: [],
  // Hubs
  allHubs: [],
  emptyHubs: [],
};

export const serviceProviderPriceCardsSlice = createSlice({
  name: "serviceProviderPriceCards",
  initialState,
  reducers: {
    setPriceCardZones: (state, action) => {
      const zones = action.payload;
      const zone = [];
      zones.forEach(({ _id, zone_name, zone_code }) => {
        const object = {
          zone: {
            _id,
            zone_name,
            zone_code,
          },
          first_kg_rate: "",
          additional_kg_rate: "",
        };
        zone.push(object);
      });
      state.priceCardZones = zone;
    },
    setPriceCardCities: (state, action) => {
      state.allCities = action.payload;
    },
    filterCitiesByDistricts: (state, action) => {
      const districtId = action.payload;
      const returnCity = state.allCities.filter(
        (data) => data.district._id === districtId
      );
      state.filteredCities = returnCity;
    },
    showEmptyCities: (state, action) => {
      const filterStatus = action.payload;
      if (filterStatus) {
        const emptyCity = state.filteredCities.filter(
          (data) => data.first_kg_rate === ""
        );
        state.emptyCities = emptyCity;
      } else {
        const emptyCity = state.allCities.filter(
          (data) => data.first_kg_rate === ""
        );
        state.emptyCities = emptyCity;
      }
    },
    updateSingleCityRow: (state, action) => {
      const { field, id, value, filteredState, checkedOnlyEmpty } =
        action.payload;
      let filteredID = "";

      const objectId = state.allCities.findIndex((obj) => obj.city._id === id);
      const emptyObjectId = state.emptyCities.findIndex(
        (obj) => obj.city._id === id
      );

      if (filteredState) {
        filteredID = state.filteredCities.findIndex(
          (obj) => obj.city._id === id
        );
      }

      if (field === "first_kg_rate") {
        state.allCities[objectId].first_kg_rate = value;
        state.emptyCities.splice(emptyObjectId, 1);
        if (filteredState)
          state.filteredCities[filteredID].first_kg_rate = value;
      }
      if (field === "additional_kg_rate") {
        state.allCities[objectId].additional_kg_rate = value;
        if (checkedOnlyEmpty)
          state.emptyCities[emptyObjectId].additional_kg_rate = value;
        if (filteredState)
          state.filteredCities[filteredID].additional_kg_rate = value;
      }
    },
    updateSelectedCities: (state, action) => {
      const { selectedRows, first_kg_rate, additional_kg_rate, filteredState } =
        action.payload;
      const selectedRowList = [];
      let filteredID = "";
      if (selectedRows.length > 0) {
        selectedRows.forEach((rowId) => {
          const objectId = state.allCities.findIndex(
            (obj) => obj.city._id === rowId
          );
          const emptyObjectId = state.emptyCities.findIndex(
            (obj) => obj.city._id === rowId
          );
          if (filteredState) {
            filteredID = state.filteredCities.findIndex(
              (obj) => obj.city._id === rowId
            );
            state.filteredCities[filteredID].first_kg_rate = first_kg_rate;
          }

          state.allCities[objectId].first_kg_rate = first_kg_rate;
          state.emptyCities.splice(emptyObjectId, 1);

          if (additional_kg_rate) {
            state.allCities[objectId].additional_kg_rate = additional_kg_rate;
            if (filteredState)
              state.filteredCities[filteredID].additional_kg_rate =
                additional_kg_rate;
          }
        });
      }
      state.selectedCities = selectedRowList;
    },
    setPriceCardsHubs: (state, action) => {
      state.allHubs = action.payload;
    },
    showEmptyHubs: (state) => {
      const emptyHubs = state.allHubs.filter(
        (data) => data.first_kg_rate === ""
      );
      state.emptyHubs = emptyHubs;
    },
    updateSingleHubRow: (state, action) => {
      const { id, field, value, checkedOnlyEmpty } = action.payload;

      const objectId = state.allHubs.findIndex((obj) => obj.hub._id === id);
      const emptyObjectId = state.emptyHubs.findIndex(
        (obj) => obj.hub._id === id
      );

      if (field === "first_kg_rate") {
        state.allHubs[objectId].first_kg_rate = value;
        state.emptyHubs.splice(emptyObjectId, 1);
      }
      if (field === "additional_kg_rate") {
        state.allHubs[objectId].additional_kg_rate = value;
        if (checkedOnlyEmpty)
          state.emptyHubs[emptyObjectId].additional_kg_rate = value;
      }
    },
    updateSelectedHubs: (state, action) => {
      const { selectedRows, first_kg_rate, additional_kg_rate } =
        action.payload;
      const selectedRowList = [];
      if (selectedRows.length > 0) {
        selectedRows.forEach((id) => {
          const objectId = state.allHubs.findIndex((obj) => obj.hub._id === id);
          const emptyObjectId = state.emptyHubs.findIndex(
            (obj) => obj.hub._id === id
          );

          state.allHubs[objectId].first_kg_rate = first_kg_rate;
          state.emptyHubs.splice(emptyObjectId, 1);
          if (additional_kg_rate) {
            state.allHubs[objectId].additional_kg_rate = additional_kg_rate;
          }
        });
      }
      state.selectedCities = selectedRowList;
    },
    resetHubsCities: (state) => {
      state.allCities = [];
      state.emptyCities = [];
      state.filteredCities = [];
      state.allHubs = [];
      state.emptyHubs = [];
    },
  },
});

export const {
  setPriceCardsHubs,
  showEmptyHubs,
  updateSingleHubRow,
  setPriceCardZones,
  setPriceCardCities,
  filterCitiesByDistricts,
  showEmptyCities,
  updateSingleCityRow,
  updateSelectedCities,
  updateSelectedHubs,
  resetHubsCities,
} = serviceProviderPriceCardsSlice.actions;

export default serviceProviderPriceCardsSlice.reducer;
