import React, { useState, useEffect } from "react";
import styled, { withTheme } from "styled-components/macro";
import Chart from "react-chartjs-2";
import { MoreVertical } from "react-feather";

import { orange, green, red, yellow, brown, blue } from "@mui/material/colors";
import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow as MuiTableRow,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import graphql from "babel-plugin-relay/macro";
import { shipmentConstants } from "../../../utils/shipmentConstants";

const Card = styled(MuiCard)(spacing);

const { fetchQuery, useRelayEnvironment } = require("react-relay");

const ChartWrapper = styled.div`
  height: 168px;
  position: relative;
`;

const DoughnutInner = styled.div`
  width: 100%;
  position: absolute;
  top: 55%;
  left: 0;
  margin-top: -22px;
  text-align: center;
  z-index: 0;
`;

const TableRow = styled(MuiTableRow)`
  height: 42px;
`;

const TableCell = styled(MuiTableCell)`
  padding-top: 0;
  padding-bottom: 0;
`;

const GreenText = styled.span`
  color: ${() => green[400]};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const RedText = styled.span`
  color: ${() => red[400]};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

function DoughnutChart({ theme }) {
  const environment = useRelayEnvironment();
  const [ShipperShipmentSummary, setShipperShipmentSummary] = useState([]);
  let data = {};

  const ShipperShipmentsSummary = async () => {
    const user = await localStorage.getItem("user");
    const userJson = JSON.parse(user);

    fetchQuery(
      environment,
      graphql`
        query ShipmentSummaryQuery($shipper_id: ID) {
          getPendingShipmentSummary(shipper_id: $shipper_id) {
            new_shipments
            ready_to_pickup
            picked_up
            at_origin_hub
            ready_to_dispatch
            in_transit
            at_transit_hub
            at_destination_hub
            out_for_delivery
            resheduled
            return_in_transit
            failed_to_deliver
            return_arrived_at_transit_hub
            return_arrived_at_origin_hub
            return_out_for_delivery
            return_delivery_resheduled
            return_delivery_failed
          }
        }
      `,
      { shipper_id: userJson._id }
    ).subscribe({
      start: () => {},
      complete: (e) => {},
      error: (errors) => {},
      next: (data) => {
        setShipperShipmentSummary(data.getPendingShipmentSummary);
      },
    });
  };
  useEffect(() => {
    ShipperShipmentsSummary();
  }, []);

  if (
    ShipperShipmentSummary.new_shipments !== 0 ||
    ShipperShipmentSummary.ready_to_pickup !== 0 ||
    ShipperShipmentSummary.picked_up !== 0 ||
    ShipperShipmentSummary.at_origin_hub !== 0 ||
    ShipperShipmentSummary.ready_to_dispatch !== 0 ||
    ShipperShipmentSummary.in_transit !== 0 ||
    ShipperShipmentSummary.at_transit_hub !== 0 ||
    ShipperShipmentSummary.at_destination_hub !== 0 ||
    ShipperShipmentSummary.out_for_delivery !== 0 ||
    ShipperShipmentSummary.resheduled !== 0 ||
    ShipperShipmentSummary.return_in_transit !== 0 ||
    ShipperShipmentSummary.failed_to_deliver !== 0 ||
    ShipperShipmentSummary.return_arrived_at_transit_hub !== 0 ||
    ShipperShipmentSummary.return_arrived_at_origin_hub !== 0 ||
    ShipperShipmentSummary.return_out_for_delivery !== 0 ||
    ShipperShipmentSummary.return_delivery_resheduled !== 0 ||
    ShipperShipmentSummary.return_delivery_failed !== 0
  ) {
    data = {
      labels: [
        shipmentConstants.NEW,
        shipmentConstants.READY_TO_PICK_UP,
        shipmentConstants.PICKED_UP,
        shipmentConstants.ARRIVED_AT_ORIGIN_HUB,
        shipmentConstants.READY_TO_DISPATCH,
        shipmentConstants.IN_TRANSIT,
        shipmentConstants.ARRIVED_AT_TRANSIT_HUB,
        shipmentConstants.ARRIVED_AT_DESTINATION_HUB,
        shipmentConstants.OUT_FOR_DELIVERY,
        shipmentConstants.RESCHEDULED,
        shipmentConstants.FAILED_TO_DELIVER,
        shipmentConstants.RETURN_IN_TRANSIT,
        shipmentConstants.RETURN_ARRIVED_AT_TRANSIT_HUB,
        shipmentConstants.RETURN_ARRIVED_AT_ORIGIN_HUB,
        shipmentConstants.RETURN_OUT_FOR_DELIVERY,
        shipmentConstants.RETURN_DELIVERY_RESCHEDULED,
        shipmentConstants.RETURN_DELIVERY_FAILED,
      ],
      datasets: [
        {
          data: [
            ShipperShipmentSummary.new_shipments,
            ShipperShipmentSummary.ready_to_pickup,
            ShipperShipmentSummary.picked_up,
            ShipperShipmentSummary.at_origin_hub,
            ShipperShipmentSummary.ready_to_dispatch,
            ShipperShipmentSummary.in_transit,
            ShipperShipmentSummary.at_transit_hub,
            ShipperShipmentSummary.at_destination_hub,
            ShipperShipmentSummary.out_for_delivery,
            ShipperShipmentSummary.resheduled,
            ShipperShipmentSummary.failed_to_deliver,
            ShipperShipmentSummary.return_in_transit,
            ShipperShipmentSummary.return_arrived_at_transit_hub,
            ShipperShipmentSummary.return_arrived_at_origin_hub,
            ShipperShipmentSummary.return_out_for_delivery,
            ShipperShipmentSummary.return_delivery_resheduled,
            ShipperShipmentSummary.return_delivery_failed,
          ],
          backgroundColor: [
            theme.palette.secondary.main,
            blue[300],
            blue[100],
            brown[500],
            brown[300],
            brown[100],
            green[500],
            green[300],
            green[100],
            red[500],
            red[300],
            red[100],
            orange[500],
            orange[300],
            orange[100],
            yellow[500],
            theme.palette.grey[500],
          ],
          borderWidth: 5,
          borderColor: theme.palette.background.paper,
        },
      ],
    };
  } else {
    data = {
      labels: [],
      datasets: [
        {
          data: [100],
          backgroundColor: [theme.palette.grey[200]],
          borderWidth: 5,
          borderColor: theme.palette.background.paper,
        },
      ],
    };
  }

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    cutout: "80%",
  };

  return (
    <Card mb={6}>
      <CardHeader
        action={
          <IconButton aria-label="settings" size="large">
            <MoreVertical />
          </IconButton>
        }
        title="Shipment Summary"
      />

      <CardContent>
        <ChartWrapper>
          <DoughnutInner>
            <Typography variant="caption">Summary</Typography>
          </DoughnutInner>
          <Chart type="doughnut" data={data} options={options} />
        </ChartWrapper>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                New
              </TableCell>
              <TableCell align="right">
                {ShipperShipmentSummary.new_shipments}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Ready To Pickup
              </TableCell>
              <TableCell align="right">
                {ShipperShipmentSummary.ready_to_pickup}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Picked up
              </TableCell>
              <TableCell align="right">
                {ShipperShipmentSummary.picked_up}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                At Origin Hub
              </TableCell>
              <TableCell align="right">
                {ShipperShipmentSummary.at_origin_hub}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Ready To Dispatch
              </TableCell>
              <TableCell align="right">
                {ShipperShipmentSummary.ready_to_dispatch}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                In Transit
              </TableCell>
              <TableCell align="right">
                {ShipperShipmentSummary.in_transit}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                At Transit Hub
              </TableCell>
              <TableCell align="right">
                {ShipperShipmentSummary.at_transit_hub}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                At Destination Hub
              </TableCell>
              <TableCell align="right">
                {ShipperShipmentSummary.at_destination_hub}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Out For Delivery
              </TableCell>
              <TableCell align="right">
                {ShipperShipmentSummary.out_for_delivery}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Resheduled
              </TableCell>
              <TableCell align="right">
                {ShipperShipmentSummary.resheduled}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Return In Transit
              </TableCell>
              <TableCell align="right">
                {ShipperShipmentSummary.return_in_transit}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Failed To Deliver
              </TableCell>
              <TableCell align="right">
                {ShipperShipmentSummary.failed_to_deliver}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Return Arrived At Transit Hub
              </TableCell>
              <TableCell align="right">
                {ShipperShipmentSummary.return_arrived_at_transit_hub}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Return Arrived At Origin Hub
              </TableCell>
              <TableCell align="right">
                {ShipperShipmentSummary.return_arrived_at_origin_hub}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Return Out For Delivery
              </TableCell>
              <TableCell align="right">
                {ShipperShipmentSummary.return_out_for_delivery}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Return Delivery Resheduled
              </TableCell>
              <TableCell align="right">
                {ShipperShipmentSummary.return_delivery_resheduled}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Return Delivery Failed
              </TableCell>
              <TableCell align="right">
                {ShipperShipmentSummary.return_delivery_failed}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

export default withTheme(DoughnutChart);
