/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UserInput = {|
  user_name: string,
  password: string,
  user_type: string,
  user_level: string,
  shipper?: ?ShipperInput,
  seller?: ?SellerInput,
  employee?: ?EmployeeInput,
  service_provider?: ?ServiceProviderInputType,
  user_status?: ?boolean,
  user_email_verify_status?: ?boolean,
  user_mobile_verify_status?: ?boolean,
  user_create_by?: ?UserInputField,
  notification_token?: ?string,
  otp_type: string,
|};
export type ShipperInput = {|
  shipper_id?: ?string,
  shipper_type?: ?string,
  shipper_first_name?: ?string,
  shipper_last_name?: ?string,
  shipper_full_name: string,
  shipper_company_name?: ?string,
  shipper_display_name?: ?string,
  shipper_phone1?: ?string,
  shipper_phone2?: ?string,
  billing_due_days?: ?number,
  payment_due_days?: ?number,
  shipper_email: string,
  shipper_contacts?: ?$ReadOnlyArray<?ContactInput>,
  shipper_address_line2?: ?string,
  shipper_city?: ?CityInput,
  service_provider?: ?string,
|};
export type ContactInput = {|
  _id: string,
  user_name: string,
  user_type: string,
  user_level: string,
  user_status: string,
|};
export type CityInput = {|
  _id: string,
  city_name: string,
|};
export type SellerInput = {|
  _id?: ?string,
  seller_id?: ?string,
  full_name?: ?string,
  other_name?: ?string,
  company_name?: ?string,
  display_name?: ?string,
  country_code?: ?string,
  phone1?: ?string,
  phone2?: ?string,
  email?: ?string,
|};
export type EmployeeInput = {|
  emp_code: string,
  emp_full_name: string,
  emp_personal_email: string,
  emp_mobile1?: ?string,
|};
export type ServiceProviderInputType = {|
  _id?: ?string,
  service_provider_id?: ?string,
  service_provider_full_name?: ?string,
  service_provider_company_name?: ?string,
  service_provider_email?: ?string,
  country_code?: ?string,
  service_provider_phone1?: ?string,
  service_provider_phone2?: ?string,
  service_provider_address_line1?: ?string,
  service_provider_address_line2?: ?string,
  service_provider_city?: ?ServiceProviderCityInput,
  service_provider_province?: ?ServiceProviderProvinceInput,
  service_provider_zip?: ?string,
  service_provider_district?: ?ServiceProviderDistrictInput,
  service_provider_address_location?: ?ServiceProviderAddrLocationInput,
  service_provider_status?: ?string,
  no_of_users?: ?number,
  hub?: ?$ReadOnlyArray<?ServiceProviderHubsInput>,
  payment_card_details?: ?$ReadOnlyArray<?ServiceProviderPaymentInput>,
  profile_setting?: ?ServiceProviderProfileSettingsInput,
|};
export type ServiceProviderCityInput = {|
  _id?: ?string,
  city_name?: ?string,
|};
export type ServiceProviderProvinceInput = {|
  _id?: ?string,
  province_name?: ?string,
  province_status?: ?boolean,
|};
export type ServiceProviderDistrictInput = {|
  _id?: ?string,
  district_name?: ?string,
  status?: ?boolean,
|};
export type ServiceProviderAddrLocationInput = {|
  type?: ?string,
  coordinates?: ?$ReadOnlyArray<?string>,
|};
export type ServiceProviderHubsInput = {|
  hub_name?: ?string,
  hub_code?: ?string,
  hub_status?: ?boolean,
  hub_address?: ?ServiceProviderHubCityInput,
|};
export type ServiceProviderHubCityInput = {|
  hub_address_city?: ?ServiceProviderHubAddrCityInput,
  hub_address_zip_code?: ?string,
  hub_address_location?: ?ServiceProviderHubAddrLocationInput,
|};
export type ServiceProviderHubAddrCityInput = {|
  _id?: ?string,
  city_name?: ?string,
|};
export type ServiceProviderHubAddrLocationInput = {|
  type?: ?string,
  coordinates?: ?$ReadOnlyArray<?string>,
|};
export type ServiceProviderPaymentInput = {|
  card_type?: ?string,
  card_holder_name?: ?string,
  card_number?: ?string,
  card_expiration_month?: ?number,
  card_expiration_year?: ?number,
  csv_code?: ?number,
|};
export type ServiceProviderProfileSettingsInput = {|
  logo?: ?string,
  banner?: ?string,
|};
export type UserInputField = {|
  _id?: ?string,
  user_name?: ?string,
  user_type?: ?string,
|};
export type ServiceProviderSignUpMutationVariables = {|
  user: UserInput
|};
export type ServiceProviderSignUpMutationResponse = {|
  +addServiceProvider: ?{|
    +_id: ?string,
    +emp_full_name: ?string,
    +employee_type: ?string,
    +emp_company_email: ?string,
    +service_provider: ?{|
      +_id: ?string,
      +service_provider_full_name: ?string,
      +service_provider_company_name: ?string,
      +service_provider_email: ?string,
    |},
    +token: ?string,
    +user_id: ?string,
    +user_setup_status: ?boolean,
    +user_activation_status: ?string,
    +service_provider_setup_status: ?boolean,
  |}
|};
export type ServiceProviderSignUpMutation = {|
  variables: ServiceProviderSignUpMutationVariables,
  response: ServiceProviderSignUpMutationResponse,
|};
*/


/*
mutation ServiceProviderSignUpMutation(
  $user: UserInput!
) {
  addServiceProvider(user: $user) {
    _id
    emp_full_name
    employee_type
    emp_company_email
    service_provider {
      _id
      service_provider_full_name
      service_provider_company_name
      service_provider_email
    }
    token
    user_id
    user_setup_status
    user_activation_status
    service_provider_setup_status
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "user"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "user",
        "variableName": "user"
      }
    ],
    "concreteType": "EmployeeReturnType",
    "kind": "LinkedField",
    "name": "addServiceProvider",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "emp_full_name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "employee_type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "emp_company_email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "EmpServiceProviderRef",
        "kind": "LinkedField",
        "name": "service_provider",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "service_provider_full_name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "service_provider_company_name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "service_provider_email",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "token",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "user_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "user_setup_status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "user_activation_status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "service_provider_setup_status",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ServiceProviderSignUpMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ServiceProviderSignUpMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "47fb68018b99ba64d34c203246dc9949",
    "id": null,
    "metadata": {},
    "name": "ServiceProviderSignUpMutation",
    "operationKind": "mutation",
    "text": "mutation ServiceProviderSignUpMutation(\n  $user: UserInput!\n) {\n  addServiceProvider(user: $user) {\n    _id\n    emp_full_name\n    employee_type\n    emp_company_email\n    service_provider {\n      _id\n      service_provider_full_name\n      service_provider_company_name\n      service_provider_email\n    }\n    token\n    user_id\n    user_setup_status\n    user_activation_status\n    service_provider_setup_status\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b0505eed9a59784f0c8d1ffb242a1f4a';

module.exports = node;
