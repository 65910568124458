import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  Avatar as MuiAvatar,
  Badge,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover as MuiPopover,
  SvgIcon,
  Tooltip,
  Typography,
} from "@mui/material";
import { Bell, Eye } from "react-feather";
import { fetchQuery, useRelayEnvironment, useMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { useSelector, useDispatch } from "react-redux";
import {
  updateNotificationsList,
  setUnReadNotificationList,
} from "../../redux/slices/ServiceProviderNotifications";
import Loader from "../Loader";
import { getCurrentDateTime } from "../../utils/commonFunctions";
import { notificationType } from "../../utils/defaultStatus";

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${(props) => props.theme.header.indicator.background};
    color: ${(props) => props.theme.palette.common.white};
  }
`;

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;

const NotificationHeader = styled(Box)`
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`;

function Notification({ notify, onUpdateSingleNotification, handleClose }) {
  const navigate = useNavigate();
  const currentUser = localStorage.getItem("user");
  const userJson = JSON.parse(currentUser);
  const { title, content, _id, created_date, updated_date, type, shipper } =
    notify;
  const { _id: shipperId } = shipper || "";

  const handleNavigation = (notifyType, notifyShipperId) => {
    switch (notifyType) {
      case notificationType.PICKUP_REQUEST:
        navigate(
          `/${userJson.service_provider.service_provider_company_name}/pickups/new_pickup_requests`
        );
        handleClose();
        break;

      case notificationType.SHIPPER_SIGNUP:
        navigate(
          `/${userJson.service_provider.service_provider_company_name}/settings/shippers`,
          {
            state: { notificationShipperId: notifyShipperId },
          }
        );
        break;

      default:
        break;
    }
  };
  return (
    <ListItem divider>
      <ListItemAvatar>
        <Avatar>
          <SvgIcon fontSize="small">
            <Bell />
          </SvgIcon>
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        sx={{
          "&.MuiListItemText-root:hover": {
            cursor: "pointer",
          },
        }}
        onClick={() => handleNavigation(type, shipperId)}
        primary={title}
        primaryTypographyProps={{
          variant: "subtitle2",
          color: "textPrimary",
        }}
        secondary={content}
      />
      <Tooltip title="Mark as read">
        <IconButton
          color="inherit"
          size="small"
          onClick={() =>
            onUpdateSingleNotification(
              _id,
              created_date,
              updated_date,
              title,
              content
            )
          }
        >
          <Eye />
        </IconButton>
      </Tooltip>
    </ListItem>
  );
}

function NavbarNotificationsDropdown() {
  const environment = useRelayEnvironment();
  const selectedPage = 0;
  const searchKey = "";
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const unReadNotificationList = useSelector(
    (state) => state.ServiceProviderNotifications.unReadNotificationList
  );

  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fetchNotifications = async () => {
    const currentUser = localStorage.getItem("user");
    const userJson = JSON.parse(currentUser);
    fetchQuery(
      environment,
      graphql`
        query NavbarNotificationsDropdownQuery(
          $owner: ID
          $search_key: String
          $page: Int
        ) {
          getAllNotifications(
            owner: $owner
            search_key: $search_key
            page: $page
          ) {
            pages
            notifications {
              _id
              type
              title
              status
              content
              created_date
              updated_date
              shipper {
                _id
                shipper_company_name
              }
            }
          }
        }
      `,
      {
        owner: userJson.service_provider._id,
        page: selectedPage,
        search_key: searchKey,
      }
    ).subscribe({
      start: () => {
        setIsLoading(true);
      },
      complete: () => {
        setIsLoading(false);
      },
      error: () => {
        setIsLoading(false);
      },
      next: (data) => {
        if (data?.getAllNotifications.notifications || "") {
          dispatch(
            setUnReadNotificationList(data.getAllNotifications.notifications)
          );
        }
      },
    });
  };

  useEffect(() => {
    fetchNotifications();
  }, [selectedPage]);

  const [commitUpdateNotification] = useMutation(graphql`
    mutation NavbarNotificationsDropdownMutation(
      $ids: [ID]
      $status: String
      $updated_date: String
    ) {
      updateListNotificationStatus(
        ids: $ids
        status: $status
        updated_date: $updated_date
      ) {
        _id
      }
    }
  `);

  const onUpdateSingleNotification = async (
    ID,
    created_date,
    updated_date,
    title,
    content
  ) => {
    try {
      commitUpdateNotification({
        variables: {
          ids: [ID],
          status: "read",
          updated_date: getCurrentDateTime(),
        },
        onCompleted() {
          dispatch(
            updateNotificationsList({
              _id: ID,
              status: "read",
              updated_date,
              created_date,
              content,
              title,
            })
          );
        },
      });
    } catch (error) {
      Error(error);
    }
  };

  return (
    <>
      <Tooltip title="Notifications">
        <IconButton color="inherit" ref={ref} onClick={handleOpen} size="large">
          <Indicator
            badgeContent={
              unReadNotificationList.length > 10
                ? "10+"
                : unReadNotificationList.length
            }
          >
            <Bell />
          </Indicator>
        </IconButton>
      </Tooltip>

      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        PaperProps={{
          style: { width: "20%" },
        }}
      >
        <NotificationHeader p={2}>
          <Typography variant="subtitle1" color="textPrimary">
            {unReadNotificationList.length} New Notifications
          </Typography>
        </NotificationHeader>

        {isLoading ? (
          <Loader />
        ) : (
          <>
            <List disablePadding>
              {unReadNotificationList.map((notify) => (
                <Notification
                  notify={notify}
                  onUpdateSingleNotification={onUpdateSingleNotification}
                  handleClose={handleClose}
                />
              ))}
            </List>
            <Box p={1} display="flex" justifyContent="center">
              <Button
                size="small"
                onClick={() =>
                  window.open(
                    `${window.location.href.split("/")[0]}/notifications`
                  )
                }
              >
                Show all notifications
              </Button>
            </Box>
          </>
        )}
      </Popover>
    </>
  );
}

export default NavbarNotificationsDropdown;
