import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import graphql from "babel-plugin-relay/macro";
import { fetchQuery, useRelayEnvironment } from "react-relay";
import axios from "axios";
import _ from "lodash";
import Loader from "../../../components/Loader";
import Page404 from "../../auth/Page404";
import ShipperLanding from "./ShipperLanding";
import { landingImagesRequestTypes } from "../../../utils/commonConstants";

function ShipperLandingIndex({ onEdit, setIsErrorUploading }) {
  const { shipperName } = useParams();
  const [currentUser, setCurrentUser] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const environment = useRelayEnvironment();
  const location = useLocation();
  const [spLogoDownloadUrl, setSpLogoDownloadUrl] = useState("");
  const [spBannerDownloadUrl, setSpBannerDownloadUrl] = useState("");

  const fetchUserDetails = async () => {
    fetchQuery(
      environment,
      graphql`
        query ShipperLandingIndexQuery($company_name: String) {
          getShipperByCompanyName(company_name: $company_name) {
            _id
            shipper_company_name
            profile_setting {
              logo
              banner
            }
          }
        }
      `,
      {
        company_name: shipperName,
      }
    ).subscribe({
      start: () => {
        setIsLoading(true);
      },
      complete: () => {
        setIsLoading(false);
      },
      error: () => {},
      next: (data) => {
        const { LANDING_IMAGE_LOGO_SHIPPER, LANDING_IMAGE_BANNER_SHIPPER } =
          landingImagesRequestTypes;

        const getDownloadUrls = async () => {
          const { _id } = data.getShipperByCompanyName || {};
          const appApiLogoUrl = `${process.env.REACT_APP_API_URL}/download/media/${LANDING_IMAGE_LOGO_SHIPPER}/${_id}`;
          const appApiBannerUrl = `${process.env.REACT_APP_API_URL}/download/media/${LANDING_IMAGE_BANNER_SHIPPER}/${_id}`;
          setIsLoading(true);
          try {
            const config = {
              method: "get",
              url: appApiLogoUrl,
              headers: {},
            };

            await axios(config)
              .then((response) => {
                const { status, request } = response;
                if (status === 200) {
                  setSpLogoDownloadUrl(request?.responseURL || "");
                  setIsLoading(false);
                }
              })
              .catch(() => {
                setIsLoading(false);
              });
          } catch (e) {
            setIsLoading(false);
          }

          try {
            const config = {
              method: "get",
              url: appApiBannerUrl,
              headers: {},
            };

            await axios(config)
              .then((response) => {
                const { status, request } = response;
                if (status === 200) {
                  setSpBannerDownloadUrl(request?.responseURL || "");
                  setIsLoading(false);
                }
              })
              .catch(() => {
                setIsLoading(false);
              });
          } catch (error) {
            setIsLoading(false);
          }
        };

        if (data.getShipperByCompanyName) {
          getDownloadUrls();
          setCurrentUser(data?.getShipperByCompanyName);
        }
      },
    });
  };

  useEffect(() => {
    fetchUserDetails();
  }, [location]);

  if (isLoading) return <Loader />;

  if (_.isEmpty(currentUser)) return <Page404 />;

  return (
    <ShipperLanding
      spLogoDownloadUrl={spLogoDownloadUrl}
      spBannerDownloadUrl={spBannerDownloadUrl}
      onEdit={onEdit}
      setIsErrorUploading={setIsErrorUploading}
      currentUser={currentUser}
      fetchUserDetails={fetchUserDetails}
    />
  );
}

export default ShipperLandingIndex;
