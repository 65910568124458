import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import styled from "styled-components/macro";
import { Formik } from "formik";
import {
  Alert as MuiAlert,
  Box,
  Button as MuiButton,
  CardContent,
  CircularProgress,
  Grid,
  TextField as MuiTextField,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { spacing } from "@mui/system";
import graphql from "babel-plugin-relay/macro";
import { useParams } from "react-router-dom";
import { fetchQuery, useRelayEnvironment, useMutation } from "react-relay";
import axios from "axios";
import {
  commonConstants,
  mediaAvailableKey,
} from "../../utils/commonConstants";
import { downloadMediaAlerts } from "../../utils/response_messages/downloadMediaAlerts";
import DistrictSelector from "../../components/auth/components/DistrictSelector";
import CitySelector from "../../components/auth/components/CitySelectorByDistrictAndServiceProvider";
import ImageSlider from "./ImageSlider";
import { formatAmount } from "../../utils/commonFunctions";
import ImageNotAvailable from "../../components/ImageNotAvailable";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const initialValues = {
  firstName: "",
  quantity: 1,
  address: "",
  phone1: "",
  phone2: "",
  zipCode: "",
  district: "",
  city: "",
};

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  quantity: Yup.number()
    .required("Required")
    .positive("Please enter valid quantity")
    .typeError("Please enter valid quantity")
    .integer("Please enter valid quantity"),
  address: Yup.string().required("Required"),
  phone1: Yup.number()
    .typeError("Please enter a valid mobile phone number")
    .test(
      "maxDigits",
      "Please enter a valid mobile phone number",
      (number) => String(number).length === 9
    )
    .positive("Please enter a valid mobile phone number")
    .required("Required"),
  phone2: Yup.number()
    .typeError("Please enter a valid mobile phone number")
    .test(
      "maxDigits",
      "Please enter a valid mobile phone number",
      (number) => String(number).length === 9
    )
    .positive("Please enter a valid mobile phone number")
    .optional("Optional"),
  district: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
});

const UserPlaceOrderForm = () => {
  const { slug } = useParams();
  const [placeOrderSuccess, setPlaceOrderSuccess] = useState("");
  const [placeOrderError, setPlaceOrderError] = useState("");
  const [state] = useState({
    checkedA: false,
    checkedB: true,
  });
  const [orderTotal, setTotal] = useState();
  const [imagesList, setImagesList] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [noImageMessage, setNoImageMessage] = useState("");

  const environment = useRelayEnvironment();
  const [urlStatus, setUrlStatus] = useState(true);
  const [orderDetails, setOrderDetails] = useState({});
  const { NO_IMAGES, AVAILABLE } = mediaAvailableKey;
  const { FAILURE } = downloadMediaAlerts;

  const getDownloadUrls = async (productUrlId) => {
    const appApiUrl = `${process.env.REACT_APP_API_URL}/download/media/IMAGE/${productUrlId}`;
    try {
      const config = {
        method: "get",
        url: appApiUrl,
        headers: {},
      };

      await axios(config)
        .then((response) => {
          const { status, data } = response;
          if (status === 200) {
            if (data?.status_key === NO_IMAGES) {
              setNoImageMessage(data.message);
            }
            if (data?.status_key === AVAILABLE)
              setImagesList(data.downloadUrls);
          }
        })
        .catch(() => {
          setErrorMessage(FAILURE);
        });
    } catch (error) {
      setErrorMessage(FAILURE);
    }
  };

  const fetchUrlList = async () => {
    fetchQuery(
      environment,
      graphql`
        query UserPlaceOrderFormQuery($slug: String) {
          getOrderURLBySlug(slug: $slug) {
            _id
            product_code
            product_name
            color
            size
            product_description
            product_image
            weight
            weight_type
            product_price
            delivery_fee
            url_status
            shipper {
              _id
              shipper_id
              shipper_full_name
              shipper_company_name
              shipper_phone1
              shipper_address_line1
              shipper_email
              pickup_locations {
                city {
                  _id
                  city_name
                }
                hub {
                  _id
                  hub_name
                  hub_code
                }
              }
            }
            service_provider {
              _id
              service_provider_full_name
              service_provider_company_name
            }
          }
        }
      `,
      {
        slug,
      }
    ).subscribe({
      complete: () => {},
      next: async (data) => {
        const { _id, url_status } = data?.getOrderURLBySlug || {};
        setUrlStatus(url_status);
        setOrderDetails(data.getOrderURLBySlug);

        await getDownloadUrls(_id);
      },
    });
  };

  useEffect(() => {
    fetchUrlList();
  }, []);

  const [commit, isInFlight] = useMutation(graphql`
    mutation UserPlaceOrderFormMutation($customer_order: OrderInputType) {
      addOrder(customer_order: $customer_order) {
        _id
        quantity
        name
        address
        mobile_number1
        mobile_number2
        district {
          _id
          district_name
        }
        city {
          _id
          city_name
        }
        postalcode
      }
    }
  `);
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (
          values,
          { resetForm, setErrors, setStatus, setSubmitting }
        ) => {
          try {
            const {
              firstName,
              quantity,
              address,
              phone1,
              phone2,
              zipCode,
              district,
              city,
            } = values;
            commit({
              variables: {
                customer_order: {
                  name: firstName,
                  quantity: parseInt(quantity),
                  address,
                  mobile_number1: phone1,
                  city: {
                    _id: city.split("_")[0],
                    city_name: city.split("_")[1],
                  },
                  mobile_number2: phone2,
                  district: {
                    _id: district.split("_")[0],
                    district_name: district.split("_")[1],
                  },
                  cod_value: parseFloat(orderTotal),
                  postalcode: zipCode,
                  order_url: {
                    product_code: orderDetails?.product_code,
                    product_name: orderDetails?.product_name,
                    color: orderDetails?.color,
                    size: orderDetails?.size,
                    product_description: orderDetails?.product_description,
                    weight: orderDetails?.weight,
                    product_price: parseFloat(orderDetails?.product_price),
                    delivery_fee: parseFloat(orderDetails?.delivery_fee),
                    shipper: {
                      pickup_locations: {
                        city: {
                          _id: orderDetails?.shipper?.pickup_locations?.city
                            ?._id,
                          city_name:
                            orderDetails?.shipper?.pickup_locations?.city
                              ?.city_name,
                        },
                        hub: {
                          _id: orderDetails?.shipper?.pickup_locations?.hub
                            ?._id,
                          hub_name:
                            orderDetails?.shipper?.pickup_locations?.hub_name,
                          hub_code:
                            orderDetails?.shipper?.pickup_locations?.hub_code,
                        },
                      },
                      _id: orderDetails?.shipper?._id,
                      shipper_id: orderDetails?.shipper?.shipper_id,
                      shipper_full_name:
                        orderDetails?.shipper?.shipper_full_name,
                      shipper_company_name:
                        orderDetails?.shipper?.shipper_company_name,
                      shipper_phone1: orderDetails?.shipper?.shipper_phone1,
                      shipper_address_line1:
                        orderDetails?.shipper?.shipper_address_line1,
                      shipper_email: orderDetails?.shipper?.shipper_email,
                    },
                    service_provider: {
                      _id: orderDetails?.service_provider?._id,
                      service_provider_full_name:
                        orderDetails?.service_provider
                          ?.service_provider_full_name,
                      service_provider_company_name:
                        orderDetails?.service_provider
                          ?.service_provider_company_name,
                    },
                  },
                },
              },
              onCompleted(data) {
                if (data.addOrder) {
                  setPlaceOrderSuccess(commonConstants.SUCCESS);
                  resetForm();
                }
              },
              onError(error) {
                setPlaceOrderSuccess(commonConstants.FAILED);
                setPlaceOrderError(error.message);
              },
            });
          } catch (error) {
            setStatus({ sent: false });
            setErrors({ submit: error.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => {
          if (!urlStatus) {
            return (
              <Alert severity="warning" my={3}>
                Sorry this product is not available{" "}
              </Alert>
            );
          }
          if (isSubmitting) {
            return (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            );
          }
          return (
            <CardContent>
              <div>
                {imagesList.length > 0 ? (
                  <ImageSlider productImages={imagesList} />
                ) : (
                  <ImageNotAvailable />
                )}
                <form onSubmit={handleSubmit}>
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="center"
                  >
                    <Grid direction="column" xs={12} sm={6} md={4}>
                      <Grid sx={{ mt: 5 }}>
                        {errorMessage && (
                          <Alert
                            mb={3}
                            severity="error"
                            onClose={() => setErrorMessage("")}
                          >
                            {errorMessage}
                          </Alert>
                        )}
                        {noImageMessage && (
                          <Alert
                            mb={3}
                            severity="warning"
                            onClose={() => setNoImageMessage("")}
                          >
                            {noImageMessage}
                          </Alert>
                        )}
                      </Grid>
                      <Grid mt={10}>
                        <Typography gutterBottom variant="h4" align="center">
                          {orderDetails?.product_name}
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{ fontWeight: "bold" }}
                            variant="body1"
                            gutterBottom
                          >
                            Price:
                          </Typography>
                          <Typography variant="body1" gutterBottom>
                            {formatAmount(orderDetails?.product_price)}
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{ fontWeight: "bold" }}
                            variant="body1"
                            gutterBottom
                          >
                            Details:
                          </Typography>

                          <Typography
                            variant="body1"
                            gutterBottom
                            align="right"
                          >
                            {orderDetails?.product_description}
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{ fontWeight: "bold" }}
                            variant="body1"
                            gutterBottom
                          >
                            Color:
                          </Typography>

                          <Typography variant="body1" gutterBottom>
                            {orderDetails?.color}
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{ fontWeight: "bold" }}
                            variant="body1"
                            gutterBottom
                          >
                            Delivery fee:
                          </Typography>

                          <Typography variant="body1" gutterBottom>
                            {formatAmount(orderDetails?.delivery_fee)}
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{ fontWeight: "bold" }}
                            variant="body1"
                            gutterBottom
                          >
                            Total:
                          </Typography>

                          <Typography variant="body1" gutterBottom>
                            {formatAmount(
                              orderDetails?.product_price * values.quantity +
                                orderDetails?.delivery_fee
                            )}
                            {setTotal(
                              orderDetails?.product_price * values.quantity +
                                orderDetails?.delivery_fee
                            )}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid>
                        <TextField
                          name="quantity"
                          label="Quantity"
                          value={values.quantity}
                          error={Boolean(touched.quantity && errors.quantity)}
                          fullWidth
                          helperText={touched.quantity && errors.quantity}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          my={2}
                        />
                      </Grid>

                      <Grid>
                        <TextField
                          name="firstName"
                          label="Name"
                          value={values.firstName}
                          error={Boolean(touched.firstName && errors.firstName)}
                          fullWidth
                          helperText={touched.firstName && errors.firstName}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          my={2}
                        />
                      </Grid>

                      <Grid>
                        <TextField
                          name="address"
                          label="Address"
                          value={values.address}
                          error={Boolean(touched.address && errors.address)}
                          fullWidth
                          helperText={touched.address && errors.address}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          my={2}
                        />
                      </Grid>

                      <Grid>
                        <TextField
                          name="phone1"
                          label="Phone Number"
                          value={values.phone1}
                          error={Boolean(touched.phone1 && errors.phone1)}
                          fullWidth
                          helperText={touched.phone1 && errors.phone1}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          my={2}
                        />
                      </Grid>

                      <Grid>
                        <TextField
                          name="phone2"
                          label="Secondary Phone Number(optional)"
                          value={values.phone2}
                          error={Boolean(touched.phone2 && errors.phone2)}
                          fullWidth
                          helperText={touched.phone2 && errors.phone2}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          my={2}
                        />
                      </Grid>

                      <Grid>
                        <DistrictSelector
                          values={values}
                          touched={touched}
                          errors={errors}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                        />
                      </Grid>

                      <Grid mt={1}>
                        <CitySelector
                          values={values}
                          touched={touched}
                          errors={errors}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          SPId={orderDetails?.service_provider?._id}
                        />
                      </Grid>

                      <Grid mt={2}>
                        <TextField
                          name="zipCode"
                          label="Postal code/Zip(optional)"
                          value={values.zipCode}
                          error={Boolean(touched.zipCode && errors.zipCode)}
                          fullWidth
                          helperText={touched.zipCode && errors.zipCode}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          my={2}
                        />
                      </Grid>

                      <Grid item md={1} xs={5}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={state.checkedB}
                              name="checkedB"
                              color="primary"
                            />
                          }
                          label="Cash"
                        />
                      </Grid>

                      <Grid item md={6} />

                      <Grid>
                        {placeOrderSuccess === commonConstants.SUCCESS && (
                          <Alert
                            severity="success"
                            my={3}
                            onClose={() => setPlaceOrderSuccess("")}
                          >
                            Your order placed successfully!
                          </Alert>
                        )}
                        {placeOrderSuccess === commonConstants.FAILED && (
                          <Alert
                            severity="error"
                            my={3}
                            onClose={() => setPlaceOrderSuccess("")}
                          >
                            {placeOrderError}
                          </Alert>
                        )}
                      </Grid>

                      <Grid>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          fullWidth
                          disabled={isInFlight}
                        >
                          Order Now
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </CardContent>
          );
        }}
      </Formik>
    </div>
  );
};

export default UserPlaceOrderForm;
