/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OrderInputType = {|
  name?: ?string,
  quantity?: ?number,
  address?: ?string,
  mobile_number1?: ?string,
  mobile_number2?: ?string,
  city?: ?OrderCityInputType,
  district?: ?OrderDistrictInputType,
  postalcode?: ?string,
  order_url?: ?ShipmentInputType,
  status?: ?string,
  cod_value?: ?number,
  shipment?: ?string,
  created_time?: ?string,
|};
export type OrderCityInputType = {|
  _id?: ?string,
  city_name?: ?string,
  hub?: ?OrderSubHubInputType,
|};
export type OrderSubHubInputType = {|
  _id?: ?string,
  hub_name?: ?string,
  hub_code?: ?string,
|};
export type OrderDistrictInputType = {|
  _id?: ?string,
  district_name?: ?string,
  status?: ?boolean,
|};
export type ShipmentInputType = {|
  _id?: ?string,
  product_token?: ?string,
  product_code?: ?string,
  product_url?: ?string,
  product_name?: ?string,
  color?: ?string,
  size?: ?string,
  product_description?: ?string,
  product_image?: ?$ReadOnlyArray<?string>,
  weight?: ?number,
  weight_type?: ?string,
  product_price?: ?number,
  delivery_fee?: ?number,
  url_status?: ?boolean,
  seller?: ?OrderSellerInput,
  shipper?: ?ShipmentShipperInput,
  service_provider?: ?OrderServiceProviderInput,
|};
export type OrderSellerInput = {|
  _id?: ?string,
  seller_id?: ?string,
  full_name?: ?string,
  company_name?: ?string,
  phone1?: ?string,
  address_line1?: ?string,
  email?: ?string,
|};
export type ShipmentShipperInput = {|
  _id: string,
  shipper_id: string,
  shipper_full_name: string,
  shipper_company_name?: ?string,
  shipper_phone1?: ?string,
  shipper_address_line1?: ?string,
  shipper_email: string,
  pickup_locations?: ?PickupLocationsInput,
|};
export type PickupLocationsInput = {|
  pickup_address_line1?: ?string,
  pickup_address_line2?: ?string,
  district?: ?DistrictInput,
  province?: ?ProvinceInput,
  city?: ?CityInput,
  zone?: ?ZoneInput,
  hub?: ?pickupsHubInputField,
  pickup_slot?: ?PickupSlotsInput,
  pickup_location?: ?LocationInput,
|};
export type DistrictInput = {|
  _id: string,
  district_name: string,
  district_status?: ?boolean,
|};
export type ProvinceInput = {|
  _id: string,
  province_name: string,
  province_status?: ?boolean,
|};
export type CityInput = {|
  _id: string,
  city_name: string,
|};
export type ZoneInput = {|
  _id?: ?string,
  zone_name?: ?string,
  zone_code?: ?string,
|};
export type pickupsHubInputField = {|
  _id?: ?string,
  hub_name?: ?string,
  hub_code?: ?string,
|};
export type PickupSlotsInput = {|
  _id?: ?string,
  slot_name?: ?string,
  start_time?: ?string,
  end_time?: ?string,
|};
export type LocationInput = {|
  type: string,
  coordinates: $ReadOnlyArray<?string>,
|};
export type OrderServiceProviderInput = {|
  _id?: ?string,
  service_provider_full_name?: ?string,
  service_provider_company_name?: ?string,
|};
export type UserPlaceOrderFormMutationVariables = {|
  customer_order?: ?OrderInputType
|};
export type UserPlaceOrderFormMutationResponse = {|
  +addOrder: ?{|
    +_id: ?string,
    +quantity: ?number,
    +name: ?string,
    +address: ?string,
    +mobile_number1: ?string,
    +mobile_number2: ?string,
    +district: ?{|
      +_id: ?string,
      +district_name: ?string,
    |},
    +city: ?{|
      +_id: ?string,
      +city_name: ?string,
    |},
    +postalcode: ?string,
  |}
|};
export type UserPlaceOrderFormMutation = {|
  variables: UserPlaceOrderFormMutationVariables,
  response: UserPlaceOrderFormMutationResponse,
|};
*/


/*
mutation UserPlaceOrderFormMutation(
  $customer_order: OrderInputType
) {
  addOrder(customer_order: $customer_order) {
    _id
    quantity
    name
    address
    mobile_number1
    mobile_number2
    district {
      _id
      district_name
    }
    city {
      _id
      city_name
    }
    postalcode
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "customer_order"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "customer_order",
        "variableName": "customer_order"
      }
    ],
    "concreteType": "Order",
    "kind": "LinkedField",
    "name": "addOrder",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "quantity",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "address",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mobile_number1",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mobile_number2",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "DistrictOrderReturnType",
        "kind": "LinkedField",
        "name": "district",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "district_name",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CityOrderReturnType",
        "kind": "LinkedField",
        "name": "city",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "city_name",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "postalcode",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserPlaceOrderFormMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserPlaceOrderFormMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "347f4b6e4b1927af3a03c70b46a71c88",
    "id": null,
    "metadata": {},
    "name": "UserPlaceOrderFormMutation",
    "operationKind": "mutation",
    "text": "mutation UserPlaceOrderFormMutation(\n  $customer_order: OrderInputType\n) {\n  addOrder(customer_order: $customer_order) {\n    _id\n    quantity\n    name\n    address\n    mobile_number1\n    mobile_number2\n    district {\n      _id\n      district_name\n    }\n    city {\n      _id\n      city_name\n    }\n    postalcode\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '70d468bff3c87c999049aaf4705ae001';

module.exports = node;
